
import { Component, Vue } from 'vue-property-decorator'
import { LocationData, FormData } from '@/types/rareTrees'
import MapLocation from '@/components/mapLocation/Index.vue'
import { FileInfo } from '@/types/common'

@Component({
  name: 'PlantDetail',
  components: { MapLocation },
  filters: {
    imgListFormat (list: FileInfo[]) {
      const imgList = list.map(item => {
        return item.filePrefix + item.fileUrl
      })
      return imgList || []
    }
  }
})
export default class extends Vue {
  private detailInfo: FormData = {
    projectId: '',
    // 所属项目
    projectName: '',
    // 苗木类别名称
    seedlingTypeName: '',
    // 苗木名称
    plantName: '',
    // 栽种日期
    plantDate: '',
    // 树龄
    treeAge: '',
    // 规格
    standard: '',
    // 苗木编号（古树特有，必填）
    seedlingCode: '',
    // 保护等级（古树特有，必填）
    protectionLevel: '',
    // 责任人（古树特有，选填）
    responsibleUser: '',
    // 责任单位（古树特有，选填）
    responsibleUnit: '',
    // 苗木位置信息（古树一个点，普苗木有多个点）
    locationList: [],
    // 苗木介绍
    seedlingIntroduce: '',
    fileList: []
  }

  private activeName = '0'
  private locationList: LocationData | null = null

  created () {
    this.getDetail()
  }

  getDetail () {
    this.$axios.get(this.$apis.seedling.selectYhSeedlingBySeedlingId, {
      seedlingId: this.$route.params.seedlingId
    }).then((res) => {
      this.detailInfo = res || {}
      this.locationList = res.locationList || []
    })
  }
}
